/* General */
body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f9f9fb;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #333;
}

h1, h2, h3 {
  color: #333;
  text-align: center;
}

/* Containers */
.manage-names-container,
.analytics-container {
  max-width: 900px;
  margin: 30px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.manage-names-container h1,
.analytics-container h1 {
  margin-bottom: 20px;
}

/* Forms */
.add-name-form {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.add-name-form input,
.add-name-form select {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.add-name-form button {
  padding: 12px 20px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-name-form button:hover {
  background-color: #45a049;
}

/* Alphabet Filter */
.alphabet-filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
}

.alphabet-button {
  padding: 10px 15px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.alphabet-button.active,
.alphabet-button:hover {
  background-color: #4caf50;
  color: white;
}

/* Filters */
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.filter-container select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

/* Table */
.names-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.names-table th,
.names-table td {
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

.names-table th {
  background: #f3f3f3;
  font-weight: bold;
}

.names-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.names-table tr:hover {
  background-color: #f1f1f1;
}

/* Buttons */
.edit-button,
.save-button,
.cancel-button,
.delete-button {
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-button {
  background-color: #ffc107;
  color: white;
}

.edit-button:hover {
  background-color: #e0a800;
}

.save-button {
  background-color: #4caf50;
  color: white;
}

.save-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.delete-button {
  background-color: #e60000;
  color: white;
}

.delete-button:hover {
  background-color: #c00000;
}

/* Analytics Page */
.analytics-container {
  max-width: 1000px;
  margin: 100px auto; /* Adjust for navbar */
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.analytics-container h1 {
  margin-bottom: 30px;
  color: #333;
}

.analytics-section {
  margin-top: 40px;
}

.analytics-section h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #4caf50;
}

.analytics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.analytics-card {
  background: #f9f9fb;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.analytics-card h3 {
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.analytics-card ul {
  list-style-type: none;
  padding: 0;
}

.analytics-card li {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.count {
  color: #888;
  font-size: 14px;
}

/* Navbar Styles */
.navbar {
  width: 100%;
  background-color: #4caf50;
  padding: 10px 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-link {
  font-size: 16px;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-link:hover {
  background-color: #ffffff;
  color: #4caf50;
  border-radius: 5px;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
}

/* Login Page */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9fb;
}

.login-box {
  background: white;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.login-box h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.error-message {
  color: #f44336;
  font-size: 14px;
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.form-input:focus {
  outline: none;
  border-color: #4caf50;
  box-shadow: 0 0 4px rgba(76, 175, 80, 0.3);
}

.login-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #45a049;
}

.login-button:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(76, 175, 80, 0.5);
}

/* Dashboard */
/* General Styles */
.dashboard-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-title {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

/* Dashboard Links */
.dashboard-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.dashboard-card {
  background-color: #f4f6f8;
  padding: 20px;
  border: 1px solid #e1e5e9;
  border-radius: 8px;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: #2c3e50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dashboard-card h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.dashboard-card p {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #7b8a96;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  background-color: #e8ecf1;
  color: #2c3e50;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-links {
    grid-template-columns: 1fr;
  }
}

.analytics-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.analytics-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.analytics-metrics ul {
  list-style-type: none;
  padding: 0;
}

.analytics-metrics li {
  font-size: 1rem;
  margin: 10px 0;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  color: #2c3e50;
}

.analytics-metrics li strong {
  color: #007bff;
}

/* Bulk upload page */
.upload-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.upload-container h1 {
  font-size: 2rem;
  color: #333;
}

.upload-container p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.upload-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:hover {
  background: #0056b3;
}

.upload-status {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

.delete-button:hover {
  background: #cc0000;
}

.delete-dropdown {
  margin-top: 10px;
  padding: 10px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.delete-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-status {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

/* Manage names */
.bulk-delete-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bulk-delete-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.bulk-delete-button:hover:enabled {
  background: #cc0000;
}
